const toastification_component = () => import('@core/components/toastification/ToastificationContent.vue');

export const showToast = (vue, title, text, icon) => {
  toastification_component().then((component) => {
    vue.$toast({
      component: component.default,
      props: {
        title,
        icon,
        text,
        variant: 'danger'
      }
    }, {
      position: 'top-center',
      timeout: 5000
    });
  });
  return false;
};

export const getIconWhite = (network) => {
  if (network === 'facebook') return 'icomoon-Facebook-2'
  if (network === 'instagram') return 'icomoon-instagram1'
  if (network === 'linkedin') return 'icomoon-Linkedin'
  if (network === 'twitch') return 'iconNetwork-twitch'
  if (network === 'twitter') return require("@/assets/images/icons/twitter-x.svg")
  if (network === 'youtube') return 'icomoon-youtube'
  if (network === 'tiktok') return 'icomoon-tiktok'
  if (network === 'spotify') return 'iconNetwork-spotify'
  if (network === 'pinterest') return 'icomoon-pinterest2'
  if (network === 'blog') return require("@/assets/images/icons/wordpress-icon-white.svg")
  if (network === 'snapchat') return 'iconNetwork-snapchat-ghost'
}
export const getColor = (network, black_tiktok = false) => {
  if (network === 'facebook') return '#3b5998'
  if (network === 'instagram') return '#d6249f'
  if (network === 'linkedin') return '#346297'
  if (network === 'twitch') return '#924DF7'
  if (network === 'twitter') return '#000000'
  if (network === 'youtube') return '#c4302b'
  if (network === 'tiktok') return black_tiktok ? '#000000' : '#ff0650'
  if (network === 'spotify') return '#64D862'
  if (network === 'pinterest') return '#E73630'
  if (network === 'blog') return '#F0A23F'
  if (network === 'snapchat') return '#F5D90D'
};
export const getColorV2 = (network, color_default = true) => {
  if (network === 'facebook') return '#3b5998'
  if (network === 'instagram') return '#d6249f'
  if (network === 'linkedin') return '#346297'
  if (network === 'twitch') return '#924DF7'
  if (network === 'twitter') return '#000000'
  if (network === 'youtube') return '#c4302b'
  if (network === 'tiktok') return '#000000'
  if (network === 'spotify') return '#64D862'
  if (network === 'pinterest') return '#E73630'
  if (network === 'blog') return color_default ? '#FFF' : '#ccc'
  if (network === 'snapchat') return '#F5D90D'
};
export const getClassNetworkColor = (network) => {
  if (network === 'facebook') return 'icomoon-Facebook-2 facebook-border'
  if (network === 'instagram') return 'icomoon-instagram1 instagram-border'
  if (network === 'linkedin') return 'icomoon-Linkedin linkedin-border'
  if (network === 'twitch') return 'iconNetwork-twitch twitch-border'
  if (network === 'twitter') return require("@/assets/images/icons/twitter-x.svg")
  if (network === 'youtube') return 'icomoon-youtube youtube-border'
  if (network === 'tiktok') return 'icomoon-tiktok tiktok-border'
  if (network === 'spotify') return 'iconNetwork-spotify spotify-border'
  if (network === 'pinterest') return 'icomoon-pinterest2 pinterest-border'
  if (network === 'blog') return require("@/assets/images/icons/wordpress-icon.svg")
  if (network === 'snapchat') return 'iconNetwork-snapchat-ghost snapchat-border'
};
export const getClassNetworkSpan = (network) => {
  if (network === 'facebook') return 'icomoon-Facebook-2 facebook'
  if (network === 'instagram') return 'icomoon-instagram1 instagram'
  if (network === 'linkedin') return 'icomoon-Linkedin linkedin'
  if (network === 'twitch') return 'iconNetwork-twitch twitch'
  if (network === 'twitter') return require("@/assets/images/icons/twitter-x-black.svg")
  if (network === 'youtube') return 'icomoon-youtube youtube'
  if (network === 'tiktok') return 'icomoon-tiktok tiktok'
  if (network === 'spotify') return 'iconNetwork-spotify spotify'
  if (network === 'pinterest') return 'icomoon-pinterest2 pinterest'
  if (network === 'blog') return 'iconNetwork-blogger blog'
  if (network === 'snapchat') return 'iconNetwork-snapchat-ghost snapchat'
};

export const getLocalNetworkName = (network) => {
  if (network === 'facebook') return 'facebook'
  if (network === 'ig') return 'instagram'
  if (network === 'instagram') return 'instagram'
  if (network === 'linkedin') return 'linkedin'
  if (network === 'twitch') return 'twitch'
  if (network === 'twitter') return 'twitter'
  if (network === 'yt') return 'youtube'
  if (network === 'youtube') return 'youtube'
  if (network === 'tt') return 'tiktok'
  if (network === 'tiktok') return 'tiktok'
  if (network === 'spotify') return 'spotify'
  if (network === 'pinterest') return 'pinterest'
  if (network === 'blog') return 'blog'
  if (network === 'snapchat') return 'snapchat'
  if (network === 'tr') return 'twitter'
  if (network === 'tw') return 'twitch'
};

export const getContent = (type) => {
  if (type === 'photo') return 'camera'
  if (type === 'video') return 'camera-video'
  if (type === 'story') return 'clock-history'
  if (type === 'streaming') return 'display'
  if (type === 'instagram_tv') return 'instagram'
  if (type === 'reel') return 'camera-reels'
  if (type === 'post') return 'images'
  if (type === 'snap') return 'file-earmark-play' 
  if (type === 'podcast') return 'soundwave'
  if (type === 'clip') return 'joystick'
  return 'award'
};
export const getDropdownNetworks = () => {
  return  [
    {
      value: "tiktok",
      network: "Tiktok",
      icon: require("@/assets/images/icons/tiktok2.svg"),
    },
    {
      value: "spotify",
      network: "Spotify",
      icon: require("@/assets/images/icons/spotify2.svg"),
    },
    {
      value: "snapchat",
      network: "Snapchat",
      icon: require("@/assets/images/icons/snapchat2.svg"),
    },
    {
      value: "blog",
      network: "Blog",
      icon: require("@/assets/images/icons/wordpress-icon.svg"),
    },
    {
      value: "pinterest",
      network: "Pinterest",
      icon: require("@/assets/images/icons/pinterest2.svg"),
    },

    {
      value: "linkedin",
      network: "LinkedIn",
      icon: require("@/assets/images/icons/linkedin2.svg"),
    },
    {
      value: "youtube",
      network: "Youtube",
      icon: require("@/assets/images/icons/youtube2.svg"),
    },
    {
      value: "instagram",
      network: "Instagram",
      icon: require("@/assets/images/icons/instagram2.svg"),
    },
    {
      value: "twitter",
      network: "Twitter",
      icon: require("@/assets/images/icons/twitter-x.svg"),
    },
    {
      value: "twitch",
      network: "Twitch",
      icon: require("@/assets/images/icons/twitch2.svg"),
    },
    {
      value: "facebook",
      network: "Facebook",
      icon: require("@/assets/images/icons/facebook2.svg"),
    },
  ]
};
export const getImageNetwork = (network) => {
  if (network === 'facebook') return require('@/assets/images/anlytics/facebook.png')
  if (network === 'instagram') return require('@/assets/images/anlytics/instagram.png')
  if (network === 'linkedin') return require('@/assets/images/anlytics/linkedin.png')
  if (network === 'twitch') return require('@/assets/images/anlytics/twitch.png')
  if (network === 'twitter') return require('@/assets/images/icons/twitter-x-black.svg')
  if (network === 'youtube') return require('@/assets/images/anlytics/youtube.png')
  if (network === 'tiktok') return require('@/assets/images/anlytics/tiktok.png')
  if (network === 'spotify') return require('@/assets/images/anlytics/spotify.png')
  if (network === 'pinterest') return require('@/assets/images/anlytics/pinterest.png')
  if (network === 'blog') return require('@/assets/images/icons/wordpress-icon.svg')
  if (network === 'snapchat') return require('@/assets/images/anlytics/snapchat.png')
};
export const getImageNetworkColor = (network) => {
  if (network === 'facebook') return require("@/assets/images/icons/facebook2.svg")
  if (network === 'instagram') return require("@/assets/images/icons/instagram2.svg")
  if (network === 'linkedin') return require("@/assets/images/icons/linkedin2.svg")
  if (network === 'twitch') return require("@/assets/images/icons/twitch2.svg")
  if (network === 'twitter') return require("@/assets/images/icons/twitter-x.svg")
  if (network === 'youtube') return require("@/assets/images/icons/youtube2.svg")
  if (network === 'tiktok') return require("@/assets/images/icons/tiktok2.svg")
  if (network === 'spotify') return require("@/assets/images/icons/spotify2.svg")
  if (network === 'pinterest') return require("@/assets/images/icons/pinterest2.svg")
  if (network === 'blog') return require("@/assets/images/icons/wordpress-icon.svg")
  if (network === 'snapchat') return require("@/assets/images/icons/snapchat2.svg")
};

export const getIconInterest = (item) => {
  if (item === "NFT") return 'cpu'
  if (item === "Gaming") return 'dice5'
  if (item === "Crypto") return 'cash'
  if (item === "Politics") return 'vector-pen'
  if (item === "Business & Careers") return 'briefcase-fill'
  if (item === 'Modeling') return 'camera-reels-fill'
  if (item === 'Fashion') return 'star-fill'
  if (item === 'Nature & landscapes') return 'image-alt'
  if (item === 'Travel') return 'geo-alt-fill'
  if (item === 'Photography') return 'camera-fill'
  if (item === 'Fitness & Gym') return 'bicycle'
  if (item === 'Trainers & Coaches') return 'bicycle'
  if (item === 'Cinema & Actors/actresses') return 'film'
  if (item === 'Management & Marketing') return 'globe'
  if (item === 'Beauty') return 'flower1'
  if (item === 'Lifestyle') return 'emoji-sunglasses'
  if (item === 'Music') return 'music-note-beamed'
  if (item === 'Family') return 'house'
  if (item === 'Finance & Economics') return 'graph-up'
  if (item === 'Art/Artists') return 'brush'
  if (item === 'Literature & Journalism') return 'book-fill'
  if (item === 'Sports with a ball') return 'circle'
  if (item === 'Kids & Toys') return 'puzzle-fill'      
  if (item === 'Shows') return 'calendar-day'
  if (item === 'Humor & Fun & Happiness') return 'emoji-laughing'
  if (item === 'Winter sports') return 'asterisk'
  if (item === 'Adult content') return 'x'
  if (item === 'Tobacco & Smoking') return 'truck'
  if (item === 'Alcohol') return 'cup-straw'
  if (item === 'Extreme Sports & Outdoor activity') return 'cloud'
  if (item === 'Machinery & Technologies') return 'cpu-fill'
  if (item === 'Luxury') return 'gem'
  if (item === 'Computers & Gadgets') return 'laptop'
  if (item === 'Clothing & Outfits') return 'bag'
  if (item === 'Mobile related') return 'music-player'     
  if (item === 'Science') return 'tree'
  if (item === 'Racing Sports') return 'trophy-fill'
  if (item === 'Cars & Motorbikes') return 'stoplights'
  if (item === 'Food & Cooking') return 'egg-fried'
  if (item === 'Architecture & Urban Design') return 'bricks'
  if (item === 'Animals') return 'bug-fill'
  if (item === 'Comics & sketches') return 'book-half'
  if (item === 'Health & Medicine') return 'plus-square'
  if (item === 'Shopping & Retail') return 'shop'
  if (item === 'Accessories & Jewellery') return 'watch'
  if (item === 'Water sports') return 'droplet-fill'
  if (item === 'DIY & Design') return 'building'
  if (item === 'Sweets & Bakery') return 'heart'  
  if (item === 'Education') return 'pencil'  

};
export const IconTypeCompetationGreen = (type) => {
  if (type === 'gift_card') return require("@/assets/images/svg/compentation_gift_green.svg")
  if (type === 'service') return require("@/assets/images/svg/compentation_service_green.svg")
  if (type === 'experience') return require("@/assets/images/svg/compentation_experience_green.svg")
};
export const IconTypeCompetation = (type) => {
  if (type === 'gift_card') return require("@/assets/images/svg/compentation_gift.svg")
  if (type === 'service') return require("@/assets/images/svg/compentation_service.svg")
  if (type === 'experience') return require("@/assets/images/svg/compentation_experience.svg")
};
export const IconTypeCompetationPrimary = (type) => {
  if (type === 'gift_card') return require("@/assets/images/svg/compentation_gift_primary.svg")
  if (type === 'service') return require("@/assets/images/svg/compentation_service_primary.svg")
  if (type === 'experience') return require("@/assets/images/svg/compentation_experience_primary.svg")
};
export const getColorPlan = (display_name) => {
  const plan_min = display_name.toLowerCase();

  if (plan_min === 'pro') return 'badge-plan-pro';
  if (plan_min === 'ia') return 'badge-plan-ia';
  if (plan_min === 'ia +') return 'badge-plan-ia_plus';


  if (display_name === 'Creador Pro') return 'badge-plan-pro';
  if (display_name === 'Creador Pro IA') return 'badge-plan-ia';
  if (display_name === 'Creador Pro IA+') return 'badge-plan-ia_plus';
};
