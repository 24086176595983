export const decimalFixed = (decimal, decimals) => {
  return decimal.toFixed(decimals)
};
export const numberFormat = (number, decimals = false) => {
  if (typeof number === 'string') {
    number = parseFloat(number);
  }
  if (decimals) {
    return number.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  } else {
    return number.toLocaleString();
  }
}
export const getFormat = (num) => {
  if (num >= 1000000) {
    const val = Math.round(num / 1000) / 1000
    return  `${Intl.NumberFormat('en-US').format(decimalFixed(val, 2))}M`
  }
  if (num >= 1000) {
    const val = Math.round(num / 10) / 100
    return `${val}K`
  }
  if (num === 0) return '0'
  return numberFormat(num.toString())
};
export const separatebycomma = (num) => {
  if (num === null) return '0'
  const result = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return result
};
export const toCurrency = (num) => {
  const val = Number(num).toLocaleString('en-US', {style: 'currency', currency: 'USD'})
  return val.slice(0,-3)
}
export const convertData = (data) => {
  const keys = Object.keys(data)
  const obj = []
  for (let index = 0; index < keys.length; index++) {
    obj.push({name: keys[index], value: data[keys[index]].value})
  }
  return obj
}
export const isVideo = (url, file = undefined) => {
  if (url === null) return ''
  if (url.split(':')[0] === 'blob') {
    return file && file.type.split('/')[0] === 'video'
  } else {
    const split = url.split(".");
    const last_one = split[split.length - 1];
    return last_one.toLowerCase() === "mp4" || last_one.toLowerCase() === "mov";
  }
};

export const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
};
export const trimText = (str) => {
  return str.trim()
};

export const urlImagetoBase64 = (url_image, callback) => {
  const xhr = new XMLHttpRequest();
  xhr.open('GET', url_image);
  xhr.responseType = 'blob';
  xhr.send();
  xhr.onload = function() {
    const reader = new FileReader();
    reader.onloadend = function() {
      callback(reader.result);
    }
    reader.readAsDataURL(xhr.response);
  };
  xhr.onerror = function () {
    callback(url_image);
  }
}

export const shortText = (number_words, text) => {
  const first_space = /^ /
  const last_space = / $/
  const many_spaces = /[ ]+/
  text = text.replace(first_space," ");
  text = text.replace(last_space,"");
  text = text.replace(many_spaces,"");
  const text_splited = text.split(' ').slice(0, number_words);
  return text_splited.join(' ')
};
export const checkNumber = (str) => {
  return !isNaN(str) && !isNaN(parseFloat(str))
};
export const nameNetworks = (network, long_name = true) => {
  if (network === 'facebook') return 'Facebook'
  if (network === 'instagram') return 'Instagram'
  if (network === 'linkedin') return 'LinkedIn'
  if (network === 'twitch') return 'Twitch'
  if (network === 'twitter') return long_name ? 'X ( antes Twitter )' : 'X'
  if (network === 'youtube') return 'YouTube'
  if (network === 'tiktok') return 'TikTok'
  if (network === 'spotify') return long_name ? 'Podcast ( Spotify )' : 'Spotify'
  if (network === 'pinterest') return 'Pinterest'
  if (network === 'blog') return 'Blog'
  if (network === 'snapchat') return 'Snapchat'
};
export const isNetworkUnavaibleToConnect = (network) => {
  if (network === 'blog' || network === 'snapchat') return true;
  return false
};

export const existsEmailOrPhoneOrUlr = (val, deny_url_and_email = true) => {
  const regex_number_phone = /(\+?\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}/g;
  const regex_email = /[^\s@]+@[^\s@]+\.[^\s@]+/;
  const regex_url = /(http[s]?:\/\/|ftp:\/\/)?(?:www\.)?(?:[\w-]+\.)+[a-z]{2,63}(?:\/\S*)?/gi;

  return ![deny_url_and_email ? regex_email.test(val) : false, regex_number_phone.test(val), deny_url_and_email ? regex_url.test(val) : false].every(i => i === false)
};